import { css } from 'styled-components';

export const base = 14;
export const baseLarge = 18;

export const FONT_STRING_AKTIV =
  '"Aktiv", -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif';

export const FONT_STRING_UNTITLED =
  '"Untitled Sans", -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif';

export default {
  aktiv: {
    regular: css`
      font-family: ${FONT_STRING_AKTIV};
      font-weight: 400;
    `,
    medium: css`
      font-family: ${FONT_STRING_AKTIV};
      font-weight: 500;
    `,
  },
  untitled: css`
    font-family: ${FONT_STRING_UNTITLED};
    font-weight: 500;
  `,
};
