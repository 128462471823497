import React from 'react';
import styled from 'styled-components';

import COLORS from '../styles/colors';
import TYPO from '../styles/typography';

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: ${COLORS.black.css};
  color: ${COLORS.white.css};
  display: flex;
  align-items: center;
  justify-content: center;
  ${TYPO.h2}
`;

type ErrorProps = {
  eventId?: string;
  error?: Error;
};

/* eslint-disable no-empty-pattern */
export default function Error({}: ErrorProps) {
  return <Container>Oops!</Container>;
}
