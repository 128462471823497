import React from 'react';
import App from 'next/app';
import mixpanel from 'mixpanel-browser';

import Meta from 'components/Meta';
import ErrorBoundary from 'components/ErrorBoundary';

import 'styles/fonts.css';
import 'utils/sentry';

if (!process.env.BUILD_ENV) {
  throw new Error('Environmental property BUILD_ENV not set');
}

// init mixpanel
if (process.env.NEXT_PUBLIC_MIXPANEL_TOKEN && typeof window !== 'undefined') {
  mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, {
    track_pageview: true,
    disable_persistence: true,
  });
}

class MyApp extends App {
  render() {
    const { pageProps } = this.props;
    const Component: any = this.props.Component; // eslint-disable-line

    const inner = () => (
      <>
        <Meta />
        <Component {...pageProps} />
      </>
    );

    // show error screen on prod
    if (process.env.BUILD_ENV !== 'local') {
      return <ErrorBoundary>{inner()}</ErrorBoundary>;
    }

    return inner();
  }
}

export default MyApp;
