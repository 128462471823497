import * as Sentry from '@sentry/react';
import { RewriteFrames } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import { StackFrame } from '@sentry/types';
import { basename, relative } from '@sentry/utils';
import pathParse from 'path-parse';

import ENDPOINTS from '../../endpoints.json';

const DSN = ENDPOINTS.sentry;

// Initialize with Data Source Name (dsn)
if (process.env.BUILD_ENV !== 'local') {
  Sentry.init({
    dsn: DSN,
    release: process.env.RELEASE,
    maxBreadcrumbs: 50,
    attachStacktrace: true,
    environment: process.env.BUILD_ENV || 'develop',
    tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1,
    integrations: [
      new Integrations.BrowserTracing({
        beforeNavigate: (context) => {
          // eslint-disable-next-line
          const { base } = pathParse(location.pathname);
          return {
            ...context,
            // You could use your UI's routing library to find the matching
            // route template here. We don't have one right now, so do some basic
            // parameter replacements.
            name: base || '/',
          };
        },
      }),
      /* eslint-disable no-underscore-dangle */
      /* eslint-disable no-param-reassign */
      new RewriteFrames({
        iteratee: (frame: StackFrame) => {
          if (!frame.filename) {
            return frame;
          }
          // Check if the frame filename begins with `/` or a Windows-style prefix such as `C:\`
          const isWindowsFrame = /^[A-Z]:\\/.test(frame.filename);
          const startsWithSlash = /^\//.test(frame.filename);
          if (isWindowsFrame || startsWithSlash) {
            const filename = isWindowsFrame
              ? frame.filename
                  .replace(/^[A-Z]:/, '') // remove Windows-style prefix
                  .replace(/\\/g, '/') // replace all `\\` instances with `/`
              : frame.filename;

            // @ts-ignore
            const root = this._root;
            const base = root
              ? relative(root, filename) // @ts-ignore-line
              : basename(filename);
            frame.filename = `app:///${base}`;
          }
          frame.filename = frame.filename.replace('/C:/_next/', '/_next/');
          return frame;
        },
      }),
      /* eslint-enable */
    ],
    beforeSend: (event, hint) => {
      // @ts-ignore
      if (hint.originalException && hint.originalException.noSentry) {
        // do not send user errors
        return null;
      }

      // @ts-ignore
      if (hint.originalException && hint.originalException.extraData) {
        // eslint-disable-next-line
        event.extra = {
          ...(event.extra || {}),
          // @ts-ignore
          ...hint.originalException.extraData,
        };
      }

      // ensure to not send local events
      if (process.env.BUILD_ENV === 'local') {
        return null;
      }

      return event;
    },
  });

  // Sentry.setContext('Flow Versions', {
  //   version: process.env.RUNTIME_VERSION,
  //   proto: process.env.PROTO_VERSION,
  //   front_end: process.env.VERSION,
  //   front_end_nice: process.env.VERSION_NICE,
  //   node_environment: process.env.NODE_ENV || 'development',
  //   build_environment: process.env.BUILD_ENV || 'local',
  // });
}

export const captureException = (error: any, extra?: Record<string, any>) => {
  if (process.env.BUILD_ENV === 'local') {
    console.error('[sentry] error captured locally', error, extra);
    return false;
  }

  // User error, no need to report
  if (error.noSentry) return false;

  Sentry.configureScope((scope) => {
    if (extra) {
      Object.keys(extra).forEach((key) => {
        scope.setExtra(key, extra[key]);
      });
    }

    if (error.extraData) {
      Object.keys(error.extraData).forEach((key) => {
        scope.setExtra(key, error.extraData[key]);
      });
    }
  });

  return Sentry.captureException(error);
};

export const captureInformation = (
  message: string,
  extra?: Record<string, any>
) => {
  if (process.env.BUILD_ENV === 'local') {
    return false;
  }

  Sentry.configureScope((scope) => {
    scope.setTag('node env', process.env.NODE_ENV || 'development');
    scope.setTag('build env', process.env.BUILD_ENV || 'local');

    if (extra) {
      Object.keys(extra).forEach((key) => {
        scope.setExtra(key, extra[key]);
      });
    }
  });

  return Sentry.captureMessage(message, Sentry.Severity.Info);
};

export const showReportDialog = (
  eventId: string | null,
  opts?: Sentry.ReportDialogOptions
) => {
  const lastEventId = eventId || Sentry.lastEventId();
  Sentry.showReportDialog({ dsn: DSN, eventId: lastEventId, ...opts });
};

export const showReportDialogSafe = () => {
  const lastEventId = Sentry.lastEventId();

  // Detect if we can attach error, otherwise we'll just create a placeholder
  if (!lastEventId) {
    captureInformation('Bug report by user (no event attached)');
  }

  showReportDialog(lastEventId, {
    labelSubmit: 'Send report',
  });
};
