import { css } from 'styled-components';

import FONTS, { base } from './fonts';
import BREAKPOINTS from './breakpoints';

export const rem = (px: number): string => `${px / base}rem`;

const styles = {
  micro: css`
    ${FONTS.aktiv.medium}
    font-size: ${rem(12)};
    line-height: ${rem(15)};
    letter-spacing: -0.02em;
  `,
  small: css`
    ${FONTS.aktiv.medium}
    font-size: ${rem(14)};
    line-height: ${rem(18)};
    letter-spacing: -0.02em;
  `,
  regular: css`
    ${FONTS.aktiv.medium}
    font-size: ${rem(16)};
    line-height: ${rem(24)};
    letter-spacing: 0em;
  `,
  regular2: css`
    ${FONTS.aktiv.regular}
    font-size: ${rem(16)};
    line-height: ${rem(24)};
    letter-spacing: 0em;
  `,
  quote: css`
    ${FONTS.aktiv.medium}
    font-size: ${rem(48)};
    line-height: ${rem(61)};
    letter-spacing: 0em;

    ${BREAKPOINTS.max.small`
     font-size: ${rem(32)};
    line-height: ${rem(40)};
  `}
  `,
  paragraph: css`
    ${FONTS.aktiv.regular}
    font-size: ${rem(18)};
    line-height: ${rem(32)};
    letter-spacing: 0em;
  `,
  paragraphSmall: css`
    ${FONTS.aktiv.regular}
    font-size: ${rem(16)};
    line-height: ${rem(24)};
    letter-spacing: 0em;
  `,

  h1: css`
    ${FONTS.untitled}
    font-size: ${rem(96)};
    line-height: ${rem(120)};
    letter-spacing: -0.02em;

    ${BREAKPOINTS.max.small`
     font-size: ${rem(64)};
    line-height: ${rem(79)};
  `}
  `,
  h2: css`
    ${FONTS.untitled}
    font-size: ${rem(64)};
    line-height: ${rem(80)};
    letter-spacing: -0.02em;
  `,
  h3: css`
    ${FONTS.untitled}
    font-size: ${rem(40)};
    line-height: ${rem(50)};
    letter-spacing: -0.06em;
  `,
  h4: css`
    ${FONTS.untitled}
    font-size: ${rem(24)};
    line-height: ${rem(30)};
    letter-spacing: -0.06em;
  `,
  h5: css`
    ${FONTS.aktiv.medium}
    font-size: ${rem(16)};
    line-height: ${rem(20)};
    letter-spacing: 0em;
  `,
  writingPost: css`
    ${FONTS.aktiv.regular}
    font-size: ${rem(24)};
    line-height: ${rem(30)};
    letter-spacing: 0em;
  `,
};

export default styles;
