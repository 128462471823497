import React from 'react';
import Head from 'next/head';

import ENDPOINTS from '../../endpoints.json';

const META = {
  title: 'Davey van der Woert',
  description: 'Portfolio of Davey van der Woert',
  keywords: [
    'webgl',
    'technology',
    'react',
    'd3',
    'data visualisation',
    'design',
    'nextjs',
  ],
  twitterImage: [ENDPOINTS.url, 'twitter.png'].join('/'),
  ogImage: [ENDPOINTS.url, 'open-graph.png'].join('/'),
  url: ENDPOINTS.url,
};

const Meta = () => {
  return (
    <Head>
      <title key="title">{META.title}</title>
      <meta key="x-ua" httpEquiv="x-ua-compatible" content="ie=edge" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />
      <meta key="author" name="author" content={META.title} />
      <meta key="description" name="description" content={META.description} />
      <meta key="keywords" name="keywords" content={META.keywords.join(' ')} />

      <meta key="og.locale" property="og:locale" content="en_gb" />
      <meta key="og.type" property="og:type" content="website" />
      <meta key="og.site_name" property="og:site_name" content={META.title} />

      <meta key="og.title" property="og:title" content={META.title} />
      <meta key="og.url" property="og:url" content={META.url} />
      <meta key="og.image" property="og:image" content={META.ogImage} />
      <meta key="og.image.width" property="og:image:width" content="1200" />
      <meta key="og.image.height" property="og:image:height" content="630" />
      <meta
        key="og.description"
        property="og:description"
        content={META.description}
      />

      <meta
        key="twitter.card"
        name="twitter:card"
        content="summary_large_image"
      />
      <meta key="twitter.title" name="twitter:title" content={META.title} />
      <meta
        key="twitter.description"
        name="twitter:description"
        content={META.description}
      />
      <meta
        key="twitter.image"
        name="twitter:image"
        content={META.twitterImage}
      />
      <link rel="shortcut icon" href="/favicon.ico" />
    </Head>
  );
};

export default Meta;
